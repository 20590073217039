import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

import "./main.scss";
import Aos from "aos";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// import Home from "./pages/landing/Lading";
import Login from "./pages/login/Login";
import { useDispatch } from "react-redux";
import Landing from "./pages/landing/Landing";
import Home from "./pages/home/Home";
import BuildWebsite from "./pages/buildWebsite/BuildWebsite";
import EditWebsite from "./pages/editWebsite/EditWebsite";
import Websites from "./pages/websites/Websites";
import Error from "./pages/error/Error";

function App() {
  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={200}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />{" "}
      <Routes>
        <Route path="/admin" element={<Login />} />
        <Route path="/admin/home" element={<Home />} />
        <Route path="/admin/buildWebsite" element={<BuildWebsite />} />
        <Route path="/admin/editWebsite/:id" element={<EditWebsite />} />
        <Route path="/admin/websites" element={<Websites />} />
        <Route path="/" element={<Error />} />
        <Route path="/:name" element={<Landing />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>{" "}
    </>
  );
}

export default App;
