import React from "react";
import { TypeAnimation } from "react-type-animation";

export default function Error() {
  return (
    <div className="error-page">
      <TypeAnimation
        preRenderFirstString={true}
        sequence={[
          500,
          "", // initially rendered starting point
          1500,
          "77 Group", // initially rendered starting point
          2500,
          "Athar Alfarasha", // initially rendered starting point
          2500,
          "Social Media Creator", // initially rendered starting point
          2500,
        ]}
        className="span1"
        speed={20}
        repeat={Infinity}
      />
    </div>
  );
}
