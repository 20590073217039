import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addWebsiteDataAction,
  editWebsiteDataAction,
  saveLogin,
} from "../../redux/slices/mainDataSlice";
import Swal from "sweetalert2";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
const ItemType = "LINK";

const LinkItem = ({ link, index, moveLink, handlerAddLink, arrangeMode }) => {
  const [, ref] = useDrop({
    accept: ItemType,
    canDrop: () => arrangeMode, // Only allow dropping if arrangeMode is true
    hover(item) {
      if (arrangeMode && item.index !== index) {
        // Check if arrangeMode is true before moving
        moveLink(item.index, index);
        item.index = index;
      }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    canDrag: () => arrangeMode, // Only allow dragging if arrangeMode is true
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div
      ref={(node) => drag(ref(node))}
      className="link"
      style={{ opacity: isDragging ? 0.5 : 1 }}
      onClick={() => handlerAddLink(link.plateform, link.icon)}
    >
      <div className={link.icon}></div>
    </div>
  );
};

const EditWebsite = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const navigate = useNavigate();

  const { id } = useParams();
  const isAuth = useSelector((state) => state.main.isAuthenticated);
  const websitesData = useSelector((state) => state.main.adminData);
  const websiteData = websitesData?.filter((website) => website.id == id)?.[0];
  if (websiteData == undefined) {
    navigate("/");
  }
  const [loading, setLoading] = useState(false);
  const [arrangeMode, setArrangeMode] = useState(false);

  const [logo, setLogo] = useState(null);
  const [logoSrc, setLogoSrc] = useState(null);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [customLink, setCustomLink] = useState("");
  const [links, setLinks] = useState([
    {
      plateform: "facebook",
      icon: "fa-brands fa-facebook",
      link: "",
      isSpecial: false,
      arrange: 0,
    },
    {
      plateform: "instagram",
      icon: "fa-brands fa-instagram",
      link: "",
      isSpecial: false,
      arrange: 1,
    },

    {
      plateform: "Whatsapp",
      icon: "fa-brands fa-whatsapp",
      link: "",
      isSpecial: false,
      arrange: 2,
    },

    {
      plateform: "Call",
      icon: "fa-solid fa-phone",
      link: "",
      isSpecial: false,
      arrange: 3,
    },

    {
      plateform: "Location",
      icon: "fa-solid fa-location-dot",
      link: "",
      isSpecial: false,
    },

    {
      plateform: "tiktok",
      icon: "fa-brands fa-tiktok",
      link: "",
      isSpecial: false,
      arrange: 4,
    },

    {
      plateform: "snapchat",
      icon: "fa-brands fa-snapchat",
      link: "",
      isSpecial: false,
      arrange: 5,
    },

    {
      plateform: "Gallery",
      icon: "fa-solid fa-store",
      link: "",
      isSpecial: true,
      arrange: 6,
    },
  ]);
  const [themeMode, setThemeMod] = useState(0);
  const [backgroundColor, setBackgroundColor] = useState("#ffffff");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [backgroundImageSrc, setBackgroundImageSrc] = useState(null);
  const [fontColor, setFontColor] = useState("");
  const dispatch = useDispatch();

  const moveLink = (fromIndex, toIndex) => {
    const updatedLinks = [...links];
    const [movedLink] = updatedLinks.splice(fromIndex, 1);

    // Update the `arrange` property for all links based on their new positions
    updatedLinks.splice(toIndex, 0, movedLink);

    // Set the `arrange` property
    updatedLinks.forEach((link, index) => {
      link.arrange = index;
    });

    setLinks(updatedLinks);
  };

  const handlerAddLink = (plateform, icon) => {
    const existingLink =
      links.find((link) => link.plateform === plateform)?.link || "";

    Swal.fire({
      html: `<i class="${icon}" style="font-size: 50px;color : #000;"></i>`,
      input: "text",
      inputValue: existingLink,
      inputAttributes: {
        autocapitalize: "off",
        placeholder:
          plateform == "Call" || plateform == "Whatsapp" ? "Number" : "Link",
        direction: "rtl",
      },
      confirmButtonText: "اضافة",
      showLoaderOnConfirm: true,
      confirmButtonColor: "#000000",
      preConfirm: async () => {},
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedLinks = links.map((link) =>
          link.plateform === plateform ? { ...link, link: result.value } : link
        );
        setLinks(updatedLinks);
        Swal.fire({
          icon: "success",
          title: "تم الحفظ بنجاح",
          showConfirmButton: false,
          timer: 1000,
        });
      }
    });
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setLogo(reader.result);
      setLogoSrc(file);
    };
    reader.onerror = (error) => {
      // Handle file reading error
      console.error("File reading error:", error);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleImageUpload2 = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setBackgroundImage(reader.result);
      setBackgroundImageSrc(file);
    };
    reader.onerror = (error) => {
      // Handle file reading error
      console.error("File reading error:", error);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handlerEditWebsite = () => {
    const data = {
      name,
      description,
      customLink: customLink == websiteData?.customURL ? "none" : customLink,
      links: JSON.stringify(links),
      themeMode,
      backgroundColor,
      fontColor,
    };
    setLoading(true);
    dispatch(
      editWebsiteDataAction({
        data,
        image: logoSrc,
        image2: backgroundImageSrc,
        id,
      })
    ).then(() => {
      setLoading(false);
    });
  };
  useEffect(() => {
    if (!isAuth) {
      const storedUserCredentials = JSON.parse(
        localStorage.getItem("userCredentials")
      );
      if (storedUserCredentials) {
        dispatch(saveLogin(storedUserCredentials));
      } else {
        navigate("/");
      }
    }
  }, [isAuth]);

  useEffect(() => {
    setLogo(
      "https://api.atharalfarasha.com/public/social/logo/" + websiteData?.logo
    );
    setLogoSrc(null);
    setName(websiteData?.name);
    setDescription(websiteData?.description);
    setCustomLink(websiteData?.customURL);
    setThemeMod(websiteData?.bgStatus);
    setLinks(websiteData && JSON.parse(websiteData?.links));
    setBackgroundColor(websiteData?.backgroundColor);
    setFontColor(websiteData?.fontColor);
    setBackgroundImage(
      "https://api.atharalfarasha.com/public/social/BG/" +
        websiteData?.backgroundImage
    );
    setBackgroundImageSrc(null);
  }, [websiteData]);

  return (
    <>
      <DndProvider backend={HTML5Backend}>
        <div className="admin-editWebsite-page">
          <Link to={"/admin/websites"} className="back-icon">
            <div className="fa-solid fa-house"></div>
          </Link>
          <div className="add-logo">
            <div className="title">Logo</div>
            <input
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              id="logo"
              onChange={handleImageUpload}
            />{" "}
            <label htmlFor="logo" className="logo">
              {logo == null ? (
                <>
                  <i className="fa-solid fa-plus"></i>
                </>
              ) : (
                <>
                  {" "}
                  <img src={logo} alt="" />
                </>
              )}
            </label>
          </div>
          <div className="data">
            <input
              type="text"
              name=""
              id=""
              placeholder="أسم العلامة التجارية"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              type="text"
              name=""
              id=""
              placeholder="وصف العلامة التجارية"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
            <div className="customURL">
              <div className="url">https://social.atharalfarasha.com/</div>
              <input
                type="text"
                placeholder="Custom Link"
                value={customLink}
                onChange={(e) => setCustomLink(e.target.value)}
              />
            </div>
          </div>
          <div className="links">
            {links.map((link, index) => (
              <LinkItem
                key={index}
                index={index}
                link={link}
                moveLink={moveLink}
                handlerAddLink={handlerAddLink}
                arrangeMode={arrangeMode} // Pass arrangeMode state here
              />
            ))}
          </div>
          <div
            className="arrange-btn"
            onClick={() => setArrangeMode(!arrangeMode)}
          >
            {arrangeMode ? <> حفظ الترتيب </> : <>ترتيب الأيقونات</>}
          </div>{" "}
          <div className="theme-group">
            <div className="head-title">الخلفيــــــة</div>
            <div className="group">
              <div className="theme">
                <input
                  type="radio"
                  name="theme"
                  id="color"
                  hidden
                  onClick={(e) => setThemeMod(0)}
                  checked={themeMode == 0 ? true : false}
                />
                <input
                  type="color"
                  name=""
                  id=""
                  value={backgroundColor}
                  onChange={(e) => setBackgroundColor(e.target.value)}
                />
              </div>
              <label htmlFor="backgroundImage" className="theme">
                <input
                  type="radio"
                  name="theme"
                  id="BG"
                  hidden
                  onClick={(e) => setThemeMod(1)}
                  checked={themeMode == 1 ? true : false}
                />{" "}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  id="backgroundImage"
                  onChange={handleImageUpload2}
                />
                <i className="fa-solid fa-image"></i>
                {backgroundImage == null ? (
                  <></>
                ) : (
                  <>
                    {" "}
                    <img src={backgroundImage} alt="" />
                  </>
                )}
              </label>
            </div>
          </div>
          <div className="font-group">
            <div className="head-title">لون الخطوط</div>
            <div className="group">
              <input
                type="color"
                name=""
                id=""
                value={fontColor}
                onChange={(e) => setFontColor(e.target.value)}
              />
            </div>
          </div>
          <div className="btn-build-website">
            {loading ? (
              <>
                <div class="loader"></div>
              </>
            ) : (
              <span onClick={() => handlerEditWebsite()}>تعديل الموقع</span>
            )}
          </div>
        </div>
      </DndProvider>
    </>
  );
};

export default EditWebsite;
