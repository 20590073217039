import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import request from "../../api/request";
import { toast } from "react-toastify";
import { json, useNavigate } from "react-router-dom";
import axios from "axios";

export const fetchAdminData = createAsyncThunk(
  "main/fetchAdminData",
  async (_, { getState, rejectWithValue }) => {
    try {
      const token = getState().main.token;
      const response = await request.get(`/api/social/adminData/${token}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchData = createAsyncThunk(
  "main/fetchData",
  async (customURL, { getState, rejectWithValue }) => {
    try {
      const token = getState().main.token;
      const response = await request.get(`/api/social/data/${customURL}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const saveResData = createAsyncThunk(
  "main/saveResData"
  // async (data, { getState, rejectWithValue }) => {
  //   try {
  //     const token = getState().main.token;

  //     const formData = new FormData();
  //     formData.append("data", JSON.stringify(data.data));
  //     formData.append("image", data.image ? data.image : "");

  //     const response = await request.post(
  //       `/api//res/edit/${data.data.id}/${token}/`,
  //       formData
  //     );
  //     return response.data;
  //   } catch (error) {
  //     return rejectWithValue(error.message);
  //   }
  // }
);
export const addWebsiteData = createAsyncThunk(
  "main/addWebsiteData",
  async (data, { getState, rejectWithValue }) => {
    try {
      const token = getState().main.token;

      const formData = new FormData();
      formData.append("data", JSON.stringify(data.data));
      formData.append("image", data.image ? data.image : "");
      formData.append("image2", data.image2 ? data.image2 : "");

      const response = await request.put(
        `/api/social/build/${token}/`,
        formData
      );
      toast.success("تم اضافه الموقع بنجاح", { autoClose: true });
      return response.data;
    } catch (error) {
      return toast.error("هذا الرابط غير متوفر ");
    }
  }
);
export const editWebsiteData = createAsyncThunk(
  "main/editWebsiteData",
  async (data, { getState, rejectWithValue }) => {
    try {
      const token = getState().main.token;

      const formData = new FormData();
      formData.append("data", JSON.stringify(data.data));
      formData.append("image", data.image ? data.image : "");
      formData.append("image2", data.image2 ? data.image2 : "");

      const response = await request.post(
        `/api/social/edit/${data.id}/${token}/`,
        formData
      );
      toast.success("تم تعديل الموقع بنجاح", { autoClose: true });
      return response.data;
    } catch (error) {
      return toast.error("هذا الرابط غير متوفر ");
    }
  }
);

export const deleteWebsiteData = createAsyncThunk(
  "main/deleteWebsiteData",
  async (id, { getState, rejectWithValue }) => {
    try {
      const token = getState().main.token;

      const response = await request.delete(
        `/api/social/delete/${id}/${token}/`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
// Slice
export const mainSlice = createSlice({
  name: "main",
  initialState: {
    auth: {},
    isFinanceLogged: false,
    error: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.auth = action.payload.data;
      const userData = {
        token: action.payload.token,
        auth: action.payload.data,
      };

      // Save user data in local storage
      localStorage.setItem("userCredentials", JSON.stringify(userData));

      state.error = null;
    },
    saveDataLocalStorage: (state, action) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.auth = action.payload.auth;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.isAuthenticated = false;
      state.user = null;
      state.error = action.payload;
    },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.auth = null;
      state.user = null;
      state.error = null;
      localStorage.removeItem("userCredentials");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAdminData.fulfilled, (state, action) => {
        state.adminData = Object.values(action.payload.data);
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.data = Object.values(action.payload.data)[0];
      });
  },
});
export const { saveDataLocalStorage, loginSuccess, loginFailure, logout } =
  mainSlice.actions;
// Export the setTheme action for use in other parts of your application
export const { setTheme } = mainSlice.actions;

export const loginAction = (data) => async (dispatch) => {
  toast.info("جاري تسجيل الدخول", { autoClose: true });
  try {
    const response = await request.post("/api/social/login", data);
    await dispatch(
      loginSuccess({
        data: response.data.data,
        token: response.data.token,
      })
    );
    await dispatch(fetchAdminData());
    toast.success(response.data.message);
  } catch (error) {
    toast.error("هناك خطا ما في اسم المستخدم او الباسورد", {
      autoClose: true,
    });
    dispatch(loginFailure(error.message));
  }
};
export const saveLogin = (credentials) => async (dispatch) => {
  try {
    await dispatch(
      saveDataLocalStorage({
        token: credentials.token,
        auth: credentials.auth,
      })
    );
    await dispatch(fetchAdminData());
  } catch (error) {
    toast.error("هناك خطا ما في اسم المستخدم او الباسورد", {
      autoClose: true,
    });
    dispatch(loginFailure(error.message));
  }
};
export const saveWebsiteDataAction = (data, image) => async (dispatch) => {
  try {
    await toast.info("جاري تعديل الموققع", { autoClose: true });

    await dispatch(saveResData(data, image));
    await dispatch(fetchAdminData());
    toast.success("تم تعديل الموقع بنجاح", { autoClose: true });
  } catch (error) {
    toast.error("حدث خطا ما ");
  }
};
export const addWebsiteDataAction =
  (data, image, image2) => async (dispatch) => {
    try {
      await toast.info("جاري اضافه الموقع", { autoClose: true });

      await dispatch(addWebsiteData(data, image, image2));
      await dispatch(fetchAdminData());
    } catch (error) {
      toast.error("حدث خطا ما  ");
    }
  };
export const editWebsiteDataAction =
  (data, image, image2, id) => async (dispatch) => {
    try {
      await toast.info("جاري تعديل الموقع", { autoClose: true });

      await dispatch(editWebsiteData(data, image, image2, id));
      await dispatch(fetchAdminData());
    } catch (error) {
      toast.error("حدث خطا ما  ");
    }
  };

export const deleteWebsiteDataAction = (id) => async (dispatch) => {
  try {
    await toast.info("جاري حذف الموقع", { autoClose: true });
    await dispatch(deleteWebsiteData(id));
    await dispatch(fetchAdminData());
    toast.success("تم حذف الموقع بنجاح", { autoClose: true });
  } catch (error) {
    toast.error("حدث خطا ما ", { autoClose: true });
  }
};
export default mainSlice.reducer;
