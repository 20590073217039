import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../../redux/slices/mainDataSlice";
import Swal from "sweetalert2";

const Landing = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const { name } = useParams();

  const websiteData = useSelector((state) => state.main.data);
  document
    .querySelector(".icongg")
    .setAttribute(
      "href",
      `http://api.atharalfarasha.com/public/social/logo/${websiteData?.logo}`
    );
  document
    .querySelector(".icongg2")
    .setAttribute(
      "href",
      `http://api.atharalfarasha.com/public/social/logo/${websiteData?.logo}`
    );

  document.title = websiteData?.name.replaceAll("-", " ").toUpperCase();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchData(name));
  }, [dispatch, name]);
  console.log(websiteData && JSON.parse(websiteData?.links));

  return (
    <>
      <div className="home-page">
        <div className="logo">
          <img
            src={`https://api.atharalfarasha.com/public/social/logo/${websiteData?.logo}`}
            alt=""
          />
        </div>
        <div className="title">{websiteData?.name}</div>
        <div className="description">{websiteData?.description}</div>

        <div className="links">
          {websiteData &&
            JSON.parse(websiteData?.links)
              .sort((a, b) => a.arrange - b.arrange) // Sort based on the arrange property
              .map((element, index) =>
                element.link == "" ? (
                  <></>
                ) : (
                  <>
                    {websiteData.bgStatus == 1 ? (
                      <>
                        <a
                          key={index}
                          href={element.link}
                          className="link"
                          style={{
                            color: websiteData.fontColor,
                            borderColor: websiteData.fontColor,
                          }}
                        >
                          <img
                            src={`https://api.atharalfarasha.com/public/social/BG/${websiteData?.backgroundImage}`}
                            alt=""
                          />
                          <div className="link-icon">
                            <i className={`${element.icon}`}></i>
                          </div>
                          <div className="link-title">{element.plateform}</div>
                        </a>
                      </>
                    ) : (
                      <>
                        <a
                          key={index}
                          href={element.link}
                          className="link"
                          style={{
                            backgroundColor: websiteData.backgroundColor,
                            color: websiteData.fontColor,
                            borderColor: websiteData.fontColor,
                          }}
                        >
                          <div className="link-icon">
                            <i className={`${element.icon}`}></i>
                          </div>
                          <div className="link-title">{element.plateform}</div>
                        </a>
                      </>
                    )}
                  </>
                )
              )}
        </div>
      </div>
    </>
  );
};

export default Landing;
