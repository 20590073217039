import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addWebsiteDataAction,
  deleteWebsiteDataAction,
  saveLogin,
} from "../../redux/slices/mainDataSlice";
import Swal from "sweetalert2";

const Websites = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const isAuth = useSelector((state) => state.main.isAuthenticated);
  const websitesData = useSelector((state) => state.main.adminData);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth) {
      const storedUserCredentials = JSON.parse(
        localStorage.getItem("userCredentials")
      );
      if (storedUserCredentials) {
        dispatch(saveLogin(storedUserCredentials));
      } else {
        navigate("/");
      }
    }
  }, [isAuth]);

  return (
    <>
      <div className="admin-websites-page">
        <div className="header">
          <Link to="/admin/home" className="logout">
            <i className="fa-solid fa-house"></i>
          </Link>
          <div className="head-title">المواقع الحالية</div>
        </div>

        <div className="websites">
          {websitesData?.map((website) => (
            <div className="website" key={website.id}>
              <div className="group">
                <div
                  className="delete-btn"
                  onClick={() => dispatch(deleteWebsiteDataAction(website.id))}
                >
                  <i className="fa-solid fa-trash-can"></i>
                </div>
                <Link to={`/admin/editWebsite/${website.id}`} className="title">
                  {website.name}
                </Link>
              </div>
              <div className="logo">
                <img
                  src={`https://api.atharalfarasha.com/public/social/logo/${website?.logo}`}
                  alt=""
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Websites;
