import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Aos from "aos";
import "aos/dist/aos.css";
import { loginAction, saveLogin } from "../../redux/slices/mainDataSlice";
const images = [
  "/images/bg3.png",
  "/images/bg4.png",
  "/images/bg5.png",
  "/images/bg6.png",
  "/images/bg7.png",
  "/images/bg8.png",
];
export default function Login() {
  const [username, setUsername] = useState("");
  const [loginStatus, setLoginStatus] = useState(false);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isAuth = useSelector((state) => state.main.isAuthenticated);
  useEffect(() => {
    if (isAuth) {
      navigate("/admin/home");
    } else {
      const storedUserCredentials = JSON.parse(
        localStorage.getItem("userCredentials")
      );
      if (storedUserCredentials) {
        dispatch(saveLogin(storedUserCredentials));
        navigate("admin/home");
      }
    }
  }, [isAuth]);

  const loginHandler = () => {
    setLoginStatus(true);
    dispatch(loginAction({ username, password }))
      .then(() => {
        // Hide loader and show success message when upload is successful
        setLoginStatus(false);
      })
      .catch(() => {});
  };
  const getArabicDay = () => {
    const days = [
      "الأحد",
      "الاثنين",
      "الثلاثاء",
      "الأربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ];
    const currentDate = new Date();
    const dayIndex = currentDate.getDay();
    return days[dayIndex];
  };
  const isMobile = /iPhone|Android/i.test(navigator.userAgent);

  const currentImageIndex = useRef(0);
  const [imageIndex, setImageIndex] = useState(0);
  const setNextBackground = () => {
    // Advance to the next background image
    currentImageIndex.current = (currentImageIndex.current + 1) % images.length;
    setImageIndex(currentImageIndex.current);
  };

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setNextBackground();
    }, 10000);
    return () => clearInterval(slideInterval);
  }, []);

  return (
    <div className="login-page-admin">
      {imageIndex === 0 && (
        <div className="background" data-aos="fade-in" data-aos-duration="1000">
          <img
            src="/images/bg3.png"
            onClick={() => setNextBackground()}
            alt=""
          />
        </div>
      )}
      {imageIndex === 1 && (
        <div className="background" data-aos="fade-in" data-aos-duration="1000">
          <img
            onClick={() => setNextBackground()}
            src="/images/bg4.png"
            alt=""
          />
        </div>
      )}
      {imageIndex === 2 && (
        <div className="background" data-aos="fade-in" data-aos-duration="1000">
          <img
            onClick={() => setNextBackground()}
            src="/images/bg5.png"
            alt=""
          />
        </div>
      )}
      {imageIndex === 3 && (
        <div className="background" data-aos="fade-in" data-aos-duration="1000">
          <img
            onClick={() => setNextBackground()}
            src="/images/bg6.png"
            alt=""
          />
        </div>
      )}
      {imageIndex === 4 && (
        <div className="background" data-aos="fade-in" data-aos-duration="1000">
          <img
            onClick={() => setNextBackground()}
            src="/images/bg7.png"
            alt=""
          />
        </div>
      )}
      {imageIndex === 5 && (
        <div className="background" data-aos="fade-in" data-aos-duration="1000">
          <img
            onClick={() => setNextBackground()}
            src="/images/bg8.png"
            alt=""
          />
        </div>
      )}

      <></>
      <div className="container">
        <div className={loginStatus ? "login-box loading" : "login-box"}>
          <div className="head-box">
            <div className="image">
              <img src="/images/user.png" alt="" />
            </div>
            <div className="group">
              <div className="title">مساء الخير</div>
              <div className="info">
                <div className="grouping">
                  <div className="icon">
                    <lord-icon
                      src="https://cdn.lordicon.com/wmlleaaf.json"
                      trigger="hover"
                      target="grouping"
                      colors="primary:#000000"
                      style={{ width: "100%", height: "100%" }}
                    ></lord-icon>
                  </div>
                  <div className="value">{getArabicDay()}</div>
                </div>
                <div className="grouping spic">
                  <div className="icon">
                    <lord-icon
                      src="https://cdn.lordicon.com/kgdqzapd.json"
                      trigger="loop"
                      state="loop-clock"
                      target="grouping"
                      colors="primary:#000000"
                      style={{ width: "100%", height: "100%" }}
                    ></lord-icon>
                  </div>
                  <div className="value">
                    {new Date().toLocaleTimeString("en-US", {
                      hour: "2-digit",
                      minute: "2-digit",
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="box">
            <div className="head-title">تسجيـل الدخــول</div>
            <div className="group">
              {" "}
              <input
                type="text"
                placeholder="أسم المستخدم"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
              />
              <input
                type="password"
                placeholder="كلمة المرور"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
              />
            </div>
            <div className="login-btn" onClick={() => loginHandler()}>
              <span> {loginStatus ? "جاري تسجيل الدخول" : " الدخــول"}</span>
            </div>
          </div>{" "}
        </div>

        <div className="left">
          {isMobile ? (
            <>
              {" "}
              <div className="group">
                <div className="box logo">
                  <img src="/images/logo.png" alt="" />
                </div>
                <div className="box copyright">
                  <div className="title">77 Group</div>
                  <div className="info">design & technical solution</div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="group">
                {" "}
                <div className=" box company-name">77 Group</div>
                <div className="box logo">
                  <img src="/images/logo.png" alt="" />
                </div>
              </div>
              <div className="box copyright">
                <div className="title">77 Group</div>
                <div className="info">design & technical solution</div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
