import axios from "axios";

const request = axios.create({
  baseURL: "https://api.atharalfarasha.com",
});

// http://localhost
// https://api.nadeemgr.com
// https://api.atharalfarasha.com
export default request;
