import { configureStore } from "@reduxjs/toolkit";
import mainDataReducer, {
  fetchData,
  loginSuccess,
} from "./slices/mainDataSlice";

export const store = configureStore({
  reducer: {
    main: mainDataReducer,
  },
});
