import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addWebsiteDataAction,
  logout,
  saveLogin,
} from "../../redux/slices/mainDataSlice";
import Swal from "sweetalert2";

const Home = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const isAuth = useSelector((state) => state.main.isAuthenticated);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (!isAuth) {
      const storedUserCredentials = JSON.parse(
        localStorage.getItem("userCredentials")
      );
      if (storedUserCredentials) {
        dispatch(saveLogin(storedUserCredentials));
      } else {
        navigate("/");
      }
    }
  }, [isAuth]);

  return (
    <>
      <div className="admin-home-page">
        <div className="header">
          <div className="logout" onClick={() => dispatch(logout())}>
            <i className="fa-solid fa-arrow-right-from-bracket"></i>
          </div>
          <div className="head-title">Social Media Creator</div>
        </div>
        <div className="logo">
          <img src="/images/logo2.svg" alt="" />
        </div>

        <div className="actions">
          <Link to="/admin/buildWebsite" className="action">
            <div className="action-icon">
              <i className="fa-solid fa-plus"></i>
            </div>
            <div className="action-title">جديـــــد</div>
          </Link>
          <Link to="/admin/websites" className="action">
            <div className="action-icon">
              <i className="fa-solid fa-share-nodes"></i>
            </div>
            <div className="action-title">المواقع الحالية</div>
          </Link>
        </div>
      </div>
    </>
  );
};

export default Home;
